/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */


$neutral-50: #F7F8FB;
$neutral-100: #F1F4F9;
$neutral-100: #E3E5ED;
$neutral-100: #C9D0DE;
$neutral-100: #8D98AD;
$neutral-100: #59637D;
$neutral-100: #3B445A;
$neutral-100: #272F45;
$neutral-100: #14182D;
$neutral-100: #080A1A;



$primario-40: #EDF2F2;
$primario-50: #DAEDF0;
$primario-100: #B4DBE0;
$primario-200: #91BABF;
$primario-300: #60959C;
$primario-400: #253032;
$primario-500: #486F75;



$secundario-50: #FAEADE;
$secundario-100: #FACDAA;
$secundario-200: #EBA671;
$secundario-300: #DB8746;
$secundario-400: #B56528;



$success-50: #DDEDDF;
$success-100: #B6E0BB;
$success-200: #7BBA82;
$success-300: #579C5F;
$success-400: #37613C;
 


$warning-50: #FAF2D9;
$warning-100: #FAE6A2;
$warning-200: #F5D97A;
$warning-300: #F0CC54;
$warning-400: #E8BE33;


$error-50: #F7CCC8;
$error-100: #F0766C;
$error-200: #DB5348;
$error-300: #DB392C;
$error-400: #A8190D;


// Generación de clases bg-primario-NUMERO
.bg-primario-50{
  background-color: $primario-50 !important;
}


.bg-primario-100 {
  background-color: $primario-100 !important;
}


.bg-primario-200 {
  background-color: $primario-200 !important;
}

.bg-primario-300 {
  background-color: $primario-300 !important;
}

.bg-primario-400 {
  background-color: $primario-400 !important;
}

 


.bg-log-reg{
  background-image: url("../assets/images/aut/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // opacity: 0.6;
}


// .bg-log-reg::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   // background-color: rgba(96, 149, 156, 0.5);
//   /* Ajusta el color y la opacidad según tus necesidades */
// }


.mat-step-header{
  border-radius: 20px !important;
}


.icon-white {
  fill: white !important;
  color: white !important;
}


.fuse-vertical-navigation-item .mat-icon{
  color: white !important;
}


 .cls-1 {
   fill: #303d8c;
   stroke-width: 0px;
 }


 .line-vertical{
  border-right: 1px solid #DEDCDC;
  height: 3rem;
 }

 .line-horizontal{
  border-bottom: 1px solid #DEDCDC;
  width: 100%;
 }


 .table {
   border-collapse: separate;
   margin: 0;
   padding: 0;
   width: 100%;
   background-color: #ffffff;
   table-layout: fixed;
   border-spacing: 0;
 }

 .table caption {
   font-size: 1.5em;
   margin: .5em 0 .75em;
   text-align: left;
 }


 .table thead tr{
  background-color: #F3F5F4;
 
 }

 .table thead tr:first-child{
  border-top-left-radius: 10px;
 }

 .table thead tr:last-child{
  border-top-right-radius: 10px;
 }



 .table tr {
   background-color: #FBFBFB; 
   padding: .35em;
 }

 .table th,
 .table td {
   padding: .9em;
   text-align: center;
 }

 .table th {
   font-size: .75em;
   letter-spacing: .1em;
 }

 @media screen and (max-width: 900px) {
   .table {
     border: 0;
   }

   .table caption {
     font-size: 1.6em;
   }

   .table thead {
     border: none;
     clip: rect(0 0 0 0);
     height: 1px;
     margin: -1px;
     overflow: hidden;
     padding: 0;
     position: absolute;
     width: 1px;
   }

   .table tr {
     display: block;
     margin-bottom: .625em;
   }

   .table td {
     border-bottom: 1px solid #ddd;
     display: block;
     font-size: 1em;
     text-align: right;
   }

   .table td::before {
     /*
    * aria-label has no advantage, it won't be read inside a .table
    content: attr(aria-label);
    */
     content: attr(data-label);
     float: left;
     font-weight: bold;
   }

   .table td:last-child {
     border-bottom: 0;
   }
 }


 .table thead tr:last-child th:first-child {
   border-top-left-radius: 10px;
 }

 .table thead tr:last-child th:last-child {
   border-top-right-radius: 10px;
 }



 @media screen and (min-width: 960px) {
 fuse-vertical-navigation {
      height: 97.1vh !important;
        min-height: 97.1vh !important;
        max-height: 97.1vh !important;
 }
 }

 #navbar {
  -webkit-app-region: drag !important;
}

.navbar-button {
  -webkit-app-region: no-drag !important;
}


